import React from 'react'
import Layout from '../components/Layout'
import Section from '../components/Section'
import SectionOverview from '../components/SectionOverview'
import ColumnsList from '../components/ColumnsList'
import Image from '../components/Image'
import ImageSVG from '../components/ImageSVG'
import Icon from '../components/Icon'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import SectionBanner from '../components/SectionBanner'
import IntroBlock from '../components/IntroBlock'
import useCheckMobile from '../utils/useCheckMobile'
import parse, { domToReact } from 'html-react-parser'
import DecorativeLink from '../components/DecorativeLink'

export default function CaseStudyTemplateLegacy({ data }) {
  const caseStudy = data.caseStudiesJson
  const imageData = data.images.edges
  const isMobile = useCheckMobile()

  return (
    <Layout id={caseStudy.jsonId}>
      <Seo
        title={caseStudy.title}
        description={caseStudy.description}
        relPath={`/case-studies/${caseStudy.jsonId}/`}
      />
      <div
        id="case-study"
        className={`page--case-study page--case-study--legacy page--case-study--${caseStudy.jsonId}`}
      >
        <div className="case-study__hero-container">
          <SectionBanner
            id="hero"
            bgColor={
              caseStudy.primaryColor !== null ? caseStudy.primaryColor : null
            }
            bgImage={`case-studies/${caseStudy.heroImage}`}
            bgTextShade={true}
            parentTitle={'Our Work'}
            title={caseStudy.title}
            pipeColor={'#fff'}
          />
        </div>

        <Section id={'intro-section'} indent constrain={1180} spacing={30}>
          <IntroBlock
            id="case-study-intro"
            heading={caseStudy.details.client}
            dividerColor={caseStudy.primaryColor}
            introLabel={caseStudy.caseStudyLabel}
            introURL={caseStudy.caseStudyURL}
            text={caseStudy.description}
          />
        </Section>

        <div style={{ marginTop: '50px' }}>
          {caseStudy.sections.map((content, i) => {
            // map our img base to the queried publicURL
            let srcPublic = []
            imageData.forEach(img => {
              if (
                content.section.overview.images !== null &&
                img.node.base === content.section.overview.images[0].image
              ) {
                srcPublic.push(img.node.publicURL)
              }
            })

            const overviewDescription = `${content.section.overview.description}`

            const options = {
              replace: domNode => {
                if (!domNode.attribs) return

                if (domNode.name === 'a') {
                  return (
                    <>
                      <DecorativeLink href={domNode.attribs.href}>
                        {domToReact(domNode.children, options)}
                      </DecorativeLink>
                    </>
                  )
                }
              },
            }

            return (
              <Section
                id={`${caseStudy.jsonId}-section--${i}`}
                indent={75}
                spacing={isMobile ? 50 : 80}
                bgColor={content.section.bgColor ? caseStudy.primaryColor : ''}
                key={i}
                constrain={1180}
              >
                {content.section.type === 'quote' ? (
                  <SectionOverview
                    align={'center'}
                    titleWrap={85}
                    style={{ paddingTop: '25px' }}
                  >
                    <Icon type={'quote'} color={caseStudy.primaryColor}></Icon>

                    <div
                      className="section-overview__quotation"
                      style={{
                        marginTop: '25px',
                        color: `${caseStudy.secondaryColor}`,
                      }}
                    >
                      {content.section.overview.quote}
                    </div>

                    <div className="piped-title">
                      {content.section.overview.name.toUpperCase()}
                    </div>

                    <div className="section-overview__footer">
                      {content.section.overview.position},{' '}
                      {content.section.overview.department}
                    </div>
                  </SectionOverview>
                ) : content.section.type === 'features' ? (
                  <ColumnsList direction={isMobile ? 'column' : 'row'}>
                    {content.section.overview.images !== null
                      ? content.section.overview.images.map((imgInfo, i) => {
                          return (
                            <ColumnsList.Column flexBasis={33} key={i}>
                              <div
                                className="icon-wrapper"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  margin: '0 auto',
                                }}
                              >
                                <Image
                                  style={{
                                    width: '50%',
                                  }}
                                  src={`case-studies/${imgInfo.image}`}
                                  alt={imgInfo.imageAlt}
                                  key={i}
                                />
                              </div>
                              <div
                                className="overview-text"
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                <h3>{imgInfo.imageHeading}</h3>
                                <p
                                  className="overview-text"
                                  dangerouslySetInnerHTML={{
                                    __html: imgInfo.imageDescription,
                                  }}
                                ></p>
                              </div>
                            </ColumnsList.Column>
                          )
                        })
                      : null}
                  </ColumnsList>
                ) : (
                  <ColumnsList
                    noDividers
                    direction={isMobile ? 'column' : 'row'}
                    order={!isMobile && i % 2 !== 0 ? 'reverse' : ''}
                  >
                    <ColumnsList.Column flexBasis={50}>
                      <div
                        className="image-block-content"
                        style={
                          isMobile
                            ? { paddingBottom: '30px', textAlign: 'center' }
                            : !isMobile && i % 2 === 0
                            ? { paddingRight: '30px', textAlign: 'center' }
                            : { paddingLeft: '30px', textAlign: 'center' }
                        }
                      >
                        {content.section.overview.images !== null
                          ? content.section.overview.images.map(
                              (imgInfo, i) => {
                                return imgInfo.image.match(/svg/g) ? (
                                  <ImageSVG
                                    src={`case-studies/${imgInfo.image}`}
                                    alt={imgInfo.imageAlt}
                                    key={i}
                                  />
                                ) : (
                                  <Image
                                    src={`case-studies/${imgInfo.image}`}
                                    alt={imgInfo.imageAlt}
                                    key={i}
                                  />
                                )
                              }
                            )
                          : null}
                      </div>
                    </ColumnsList.Column>
                    <ColumnsList.Column flexBasis={50}>
                      {!content.section.bgColor &&
                      content.section.overview.heading ? (
                        <SectionOverview divider titleWrap={85}>
                          <h2>{content.section.overview.heading}</h2>
                        </SectionOverview>
                      ) : content.section.bgColor &&
                        content.section.overview.heading ? (
                        <SectionOverview
                          divider
                          dividerColor={'white'}
                          titleWrap={85}
                        >
                          <h2>{content.section.overview.heading}</h2>
                        </SectionOverview>
                      ) : null}
                      <p>{parse(overviewDescription, options)}</p>
                      {i === 0 ? (
                        <span>
                          <div
                            style={{
                              lineHeight: '1.8',
                            }}
                          >
                            {caseStudy.details.client ? (
                              <div>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                  }}
                                >
                                  Client:
                                </span>
                                {caseStudy.details.client}
                              </div>
                            ) : null}
                            {caseStudy.details.project ? (
                              <div>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                  }}
                                >
                                  Project:
                                </span>
                                {caseStudy.details.project}
                              </div>
                            ) : null}
                            {caseStudy.details.technology ? (
                              <div>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                  }}
                                >
                                  Technology:
                                </span>
                                {caseStudy.details.technology}
                              </div>
                            ) : null}
                          </div>
                        </span>
                      ) : null}
                    </ColumnsList.Column>
                  </ColumnsList>
                )}
              </Section>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CaseStudyTemplate($jsonId: String!) {
    caseStudiesJson(jsonId: { eq: $jsonId }) {
      jsonId
      description
      caseStudyURL
      caseStudyLabel
      details {
        client
        project
        technology
      }
      heroImage
      heroImageAlt
      logo
      logoAlt
      primaryColor
      title
      sections {
        section {
          bgColor
          overview {
            description
            heading
            images {
              image
              imageAlt
              imageHeading
              imageDescription
            }
          }
          type
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "case-studies" } }) {
      edges {
        node {
          id
          publicURL
          base
          extension
        }
      }
    }
  }
`
