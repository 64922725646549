import React from 'react';
import findByType from '../utils/findByType';
import Image from './Image'
import useCheckMobile from '../utils/useCheckMobile'

const Column = () => null;
const ListItem = () => null;

const RenderListItem = ({items}) => {
  return !items ? null : (
    <div className={`list-container`}>
      <ul className={`column-list`}>
        {
					items.map((item,i) => {
						return (
							<li key={i}>
								{item.props.children}
							</li>
						)
					})
				}
			</ul>
		</div>
	)
}

const RenderColumns = ({ children, noDividers, isMobileLocal }) => {
	const column = findByType(children, Column);
	if(!column) return null;

	let columnsAndDividers = [];
	if(noDividers){
		columnsAndDividers = column;
	}
	else{
		for(let i = 0; i < column.length; i++){
			let col = column[i];
			columnsAndDividers.push(col);

			if(i !== column.length-1){
				columnsAndDividers.push({isDivider: true});
			}
		}
	}


	return columnsAndDividers.map((col,i) => {
		const isDivider =  col.isDivider;
		const defaultFlexBasis = Math.floor(100 / column.length);

		if(isDivider){
			return (
				<div className="column-list-column column-divider" key={i}>&nbsp;</div>
			)
		}
		else {
			let imgSrc = col.props.imageSrc,
			listColor = col.props.listColor ? col.props.listColor.toLowerCase() : '',
			listItems = findByType(col.props.children, ListItem),
			_style = isMobileLocal ? {} : {
				flexBasis: col.props.flexBasis === 'auto' ? 'auto' : `${col.props.flexBasis || defaultFlexBasis}%`
			};

			return (
				<div style={imgSrc ? Object.assign(_style, {paddingRight: '0px'}) : _style} className={`column-list-column ${imgSrc ? 'is-image' : ''} ${listColor} ${column.length < 2 ? 'single-item' : ''}`} key={i}>
					{
						imgSrc ?
							<div className={`column-header-image`}>
								<Image src={imgSrc} alt={col.props.title || imgSrc }></Image>
								{ listItems ? <div className={'vertical-line'}></div> : null }
							</div>
						: null
					}
					{
						col.props.title ?
							<div className="column-header-title">
								<h3 style={imgSrc ? {margin: '5px 0px 5px 25px', height: '50px'} : {}}>{col.props.title}</h3>
							</div>
						: null
					}
					<RenderListItem items={listItems}></RenderListItem>
					<div className="column-wrapper" style={imgSrc ? { marginLeft: '5px' } : col.props.style ? col.props.style : {}}>{col.props.children}</div>
				</div>
			)
		}
	});
}

function ColumnsList(props) {
	const isMobileLocal= useCheckMobile();
	const type = props.direction === "column" ? "column" : "row";
	const order = props.order === "reverse" ? "-reverse" : "";
	const Container = {
		display: 'flex',
		flexDirection: type + order
	}

	return (
		<div className={`columns-list-container ${type}${order}`} style={Container}>
			<RenderColumns children={props.children} noDividers={props.noDividers} isMobileLocal={isMobileLocal}></RenderColumns>
		</div>
	)
}

ColumnsList.Column = Column;
Column.ListItem = ListItem;
export default ColumnsList;
